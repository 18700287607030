import { createTheme } from "@mui/material";
import ShamelessOtf from "./font/ShamelessDeluxe.otf";

const lightPalette = {
  mode: "light",
  primary: {
    main: "#fff",
    contrastText: "#081D49",
  },
  secondary: {
    main: "#081D49",
  },
  background: {
    default: "#081D49",
    paper: "#081D49",
  },
  text: {
    primary: "#fff",
  },
  divider: "#fff",
} as const;

// A custom theme for this app
const theme = () =>
  createTheme({
    palette: lightPalette,
    spacing: (factor: number) => `${10 * factor}px`,
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: "Josefin Sans, sans-serif",
      fontWeightRegular: 300,
      fontWeightMedium: 400,
      h1: {
        fontFamily: "ShamelessDeluxe, Herr Von Muellerhoff, cursive",
      },
      h2: {
        fontFamily: "ShamelessDeluxe, Herr Von Muellerhoff, cursive",
      },
      h5: {
        fontFamily: "EB Garamond, serif",
        fontStyle: "italic",
        fontWeight: 500,
        letterSpacing: "0.1rem",
      },
      h6: {
        fontFamily: "EB Garamond, serif",
        fontStyle: "italic",
        fontWeight: 400,
        letterSpacing: "0.1rem",
      },
      subtitle1: {
        fontFamily: "EB Garamond, serif",
        fontStyle: "normal",
        fontWeight: 500,
        letterSpacing: "0.1rem",
        textTransform: "uppercase",
      },
      body1: {
        fontSize: "1.15rem",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            "@font-face": {
              fontFamily: "ShamelessDeluxe",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
              src: `local('ShamelessDeluxe'), url(${ShamelessOtf}) format('opentype')`,
            },
          },
          body: {
            "::-webkit-scrollbar": {
              width: 8,
              height: 8,
              backgroundColor: lightPalette.primary.contrastText,
              borderRadius: 8,
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: lightPalette.primary.main,
              borderRadius: 8,
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: "1.0em",
          },
        },
      },
    },
  });

export default theme;
