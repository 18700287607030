import { alpha, Box, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import girlImage from "../../img/photos/story/1-girl.jpg";
import girlImageThumb from "../../img/photos/story/1-girl.thumb.jpg";
import boyImage from "../../img/photos/story/2-boy.jpg";
import boyImageThumb from "../../img/photos/story/2-boy.thumb.jpg";
import meetingImage from "../../img/photos/story/3-first-meeting.jpg";
import meetingImageThumb from "../../img/photos/story/3-first-meeting.thumb.jpg";
import kissImage from "../../img/photos/story/4-first-kiss.png";
import kissImageThumb from "../../img/photos/story/4-first-kiss.thumb.png";
import experiencesImage from "../../img/photos/story/5-experiences.jpg";
import experiencesImageThumb from "../../img/photos/story/5-experiences.thumb.jpg";
import engageImage from "../../img/photos/story/6-engage.jpg";
import engageImageThumb from "../../img/photos/story/6-engage.thumb.jpg";
import weddingImage from "../../img/photos/story/7-wedding.png";
import weddingImageThumb from "../../img/photos/story/7-wedding.thumb.png";
import { ImageWithDialog } from "./ImageWithDialog";
import girlPreviewImage from "../../img/photos/story/previews/1-girl.jpg";
import girlPreviewImageThumb from "../../img/photos/story/previews/1-girl.thumb.jpg";
import boyPreviewImage from "../../img/photos/story/previews/2-boy.jpg";
import boyPreviewImageThumb from "../../img/photos/story/previews/2-boy.thumb.jpg";
import meetingPreviewImage from "../../img/photos/story/previews/3-first-meeting.jpg";
import meetingPreviewImageThumb from "../../img/photos/story/previews/3-first-meeting.thumb.jpg";
import kissPreviewImage from "../../img/photos/story/previews/4-first-kiss.png";
import kissPreviewImageThumb from "../../img/photos/story/previews/4-first-kiss.thumb.png";
import experiencesPreviewImage from "../../img/photos/story/previews/5-experiences.jpg";
import experiencesPreviewImageThumb from "../../img/photos/story/previews/5-experiences.thumb.jpg";
import engagePreviewImage from "../../img/photos/story/previews/6-engage.jpg";
import engagePreviewImageThumb from "../../img/photos/story/previews/6-engage.thumb.jpg";
import weddingPreviewImage from "../../img/photos/story/previews/7-wedding.png";
import weddingPreviewImageThumb from "../../img/photos/story/previews/7-wedding.thumb.png";

type ItemType = "girl" | "boy" | "meeting" | "kiss" | "experience" | "engage" | "wedding";

const ITEMS: ItemType[] = ["girl", "boy", "meeting", "kiss", "experience", "engage", "wedding"];

const PREVIEW_IMAGE_MAP: { [key in ItemType]: { url: string; thumbnail: string } } = {
  girl: {
    url: girlPreviewImage,
    thumbnail: girlPreviewImageThumb,
  },
  boy: {
    url: boyPreviewImage,
    thumbnail: boyPreviewImageThumb,
  },
  meeting: {
    url: meetingPreviewImage,
    thumbnail: meetingPreviewImageThumb,
  },
  kiss: {
    url: kissPreviewImage,
    thumbnail: kissPreviewImageThumb,
  },
  experience: {
    url: experiencesPreviewImage,
    thumbnail: experiencesPreviewImageThumb,
  },
  engage: {
    url: engagePreviewImage,
    thumbnail: engagePreviewImageThumb,
  },
  wedding: {
    url: weddingPreviewImage,
    thumbnail: weddingPreviewImageThumb,
  },
};

const IMAGE_MAP: { [key in ItemType]: { url: string; thumbnail: string } } = {
  girl: {
    url: girlImage,
    thumbnail: girlImageThumb,
  },
  boy: {
    url: boyImage,
    thumbnail: boyImageThumb,
  },
  meeting: {
    url: meetingImage,
    thumbnail: meetingImageThumb,
  },
  kiss: {
    url: kissImage,
    thumbnail: kissImageThumb,
  },
  experience: {
    url: experiencesImage,
    thumbnail: experiencesImageThumb,
  },
  engage: {
    url: engageImage,
    thumbnail: engageImageThumb,
  },
  wedding: {
    url: weddingImage,
    thumbnail: weddingImageThumb,
  },
};

const PREFIX = "Story";
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  image: `${PREFIX}-image`,
  content: `${PREFIX}-content`,
  box: `${PREFIX}-box`,
  lastBox: `${PREFIX}-lastBox`,
  topText: `${PREFIX}-topText`,
  bottomText: `${PREFIX}-bottomText`,
  lastText: `${PREFIX}-lastText`,
  header: `${PREFIX}-header`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: theme.spacing(4),
    background: `linear-gradient(270deg, ${alpha(theme.palette.background.default, 0)} 0%, ${
      theme.palette.background.default
    } 100%)`,
    zIndex: 2,
    userSelect: "none",
    pointerEvents: "none",
  },
  "&:after": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: theme.spacing(4),
    background: `linear-gradient(90deg, ${alpha(theme.palette.background.default, 0)} 0%, ${
      theme.palette.background.default
    } 100%)`,
    zIndex: 2,
    userSelect: "none",
    pointerEvents: "none",
  },
  [`& .${classes.wrapper}`]: {
    display: "flex",
    gap: theme.spacing(20),
    overflowX: "auto",
    padding: theme.spacing(0, 4),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 8),
    },
  },
  [`& .${classes.box}`]: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    [`&:not(.${classes.lastBox}):after`]: {
      content: "''",
      position: "absolute",
      left: "250px",
      width: "calc(100% - 80px)",
      height: "2px",
      background: alpha(theme.palette.primary.main, 0.5),
    },
    [theme.breakpoints.down("md")]: {
      [`& .${classes.topText}`]: {
        display: "none",
      },
      "&:after": {
        top: "109px",
      },
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      [`&:nth-child(2n+1) .${classes.topText}`]: {
        opacity: 0,
        userSelect: "none",
        pointerEvents: "none",
      },
      [`&:nth-child(2n) .${classes.bottomText}`]: {
        opacity: 0,
        userSelect: "none",
        pointerEvents: "none",
      },
    },
  },
  [`& .${classes.image}`]: {
    borderWidth: theme.spacing(1),
    minWidth: "220px",
    maxWidth: "220px",
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.lastText}`]: {
    borderWidth: theme.spacing(1),
    minWidth: "220px",
    maxWidth: "220px",
    minHeight: "220px",
    maxHeight: "220px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  [`& .${classes.content}`]: {
    minWidth: "min(60vw, 400px)",
    maxWidth: "400px",
  },
  [`& .${classes.header}`]: {
    position: "relative",
    "&:before": {
      position: "absolute",
      content: "''",
      top: theme.spacing(-1),
      bottom: theme.spacing(-1),
      left: theme.spacing(-2),
      width: "1px",
      background: alpha(theme.palette.divider, 0.5),
    },
  },
}));

export const Story: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <Box className={classes.wrapper}>
        {ITEMS.map((item, index) => (
          <Box className={classes.box} key={index}>
            <Box className={classes.topText}>
              <Box className={classes.header}>
                <Typography variant="h6">{t(`home.sections.story.items.${item}.date`)}</Typography>
                <Typography variant="h6">{t(`home.sections.story.items.${item}.title`)}</Typography>
              </Box>
              <Typography className={classes.content} mt={2}>
                {t(`home.sections.story.items.${item}.content`)}
              </Typography>
            </Box>
            <Paper variant="outlined" className={classes.image} elevation={0}>
              <ImageWithDialog
                previewSrc={PREVIEW_IMAGE_MAP[item]}
                originalSrc={IMAGE_MAP[item]}
                label={t(`home.sections.story.items.${item}.title`)}
              />
            </Paper>
            <Box className={classes.bottomText}>
              <Box className={classes.header}>
                <Typography variant="h6">{t(`home.sections.story.items.${item}.date`)}</Typography>
                <Typography variant="h6">{t(`home.sections.story.items.${item}.title`)}</Typography>
              </Box>
              <Typography className={classes.content} mt={2}>
                {t(`home.sections.story.items.${item}.content`)}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className={`${classes.box} ${classes.lastBox}`}>
          <Paper variant="outlined" className={classes.lastText} elevation={0}>
            <Typography variant="h5" textAlign="center" sx={{ margin: "auto", p: 1 }} textTransform="uppercase">
              {t("home.sections.story.whatNext")}
            </Typography>
          </Paper>
        </Box>
      </Box>
    </Root>
  );
};
