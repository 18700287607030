import React, { createContext, useCallback, useContext, useState } from "react";

type BaseModalProps = { onClose?: () => void };

interface ModalDialog {
  component: React.ComponentType<any> | null;
  props: BaseModalProps;
}

interface ModalContext extends ModalDialog {
  showModal: <P extends {}>(component: React.ComponentType<P>, props: Omit<P, "onClose"> & BaseModalProps) => void;
  hideModal: () => void;
}

const modalContext = createContext<ModalContext>({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
});

interface ModalProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ModalDialog>({
    component: null,
    props: {},
  });

  const showModal = useCallback(
    <P extends {}>(component: React.ComponentType<P>, props?: Omit<P, "onClose"> & BaseModalProps): void => {
      component &&
        setModal({
          component,
          props: props || {},
        });
    },
    [],
  );

  const hideModal = () => {
    if (modal.props.onClose) {
      modal.props.onClose();
    }
    setModal({
      component: null,
      props: {},
    });
  };

  return <modalContext.Provider value={{ showModal, hideModal, ...modal }}>{children}</modalContext.Provider>;
};

export const useModal = () => useContext<ModalContext>(modalContext);
