import { Button, Typography } from "@mui/material";
import React from "react";
import i18n from "../i18n";

const languagesList = ["sk", "en"];

const getNextLanguage = () => {
  const activeIndex = languagesList.findIndex((x) => x === i18n.language);
  let nextLanguage = activeIndex + 1;
  if (nextLanguage === languagesList.length) {
    nextLanguage = 0;
  }
  return languagesList[nextLanguage];
};

export const LanguageSwitcher: React.FC = () => {
  const changeLanguage = async () => {
    const nextLanguage = getNextLanguage();
    await i18n.changeLanguage(nextLanguage);
  };

  return (
    <Button onClick={changeLanguage} sx={{ minWidth: "45px" }}>
      <Typography variant="subtitle1">{i18n.language}</Typography>
    </Button>
  );
};
