import { alpha, Box, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import londonPhoto from "../../img/photos/2015_london.jpg";
import londonPhotoThumb from "../../img/photos/2015_london.thumb.jpg";
import switzerlandPhoto from "../../img/photos/2016_switzerland.jpg";
import switzerlandPhotoThumb from "../../img/photos/2016_switzerland.thumb.jpg";
import niagaraPhoto from "../../img/photos/2019_05_niagara.jpg";
import niagaraPhotoThumb from "../../img/photos/2019_05_niagara.thumb.jpg";
import nyPhoto from "../../img/photos/2019_05_NY.jpg";
import nyPhotoThumb from "../../img/photos/2019_05_NY.thumb.jpg";
import roadtripPhoto from "../../img/photos/2019_07_us_roadtrip.jpg";
import roadtripPhotoThumb from "../../img/photos/2019_07_us_roadtrip.thumb.jpg";
import spainPhoto from "../../img/photos/2019_09_spain.jpg";
import spainPhotoThumb from "../../img/photos/2019_09_spain.thumb.jpg";
import barcelonaPhoto from "../../img/photos/2022_09_barcelona_3.jpg";
import barcelonaPhotoThumb from "../../img/photos/2022_09_barcelona_3.thumb.jpg";
import lasPalmasPhoto from "../../img/photos/2023_las_palmas.jpg";
import lasPalmasPhotoThumb from "../../img/photos/2023_las_palmas.thumb.jpg";
import londonPreviewPhoto from "../../img/photos/previews/2015_london.jpg";
import londonPreviewPhotoThumb from "../../img/photos/previews/2015_london.thumb.jpg";
import switzerlandPreviewPhoto from "../../img/photos/previews/2016_switzerland.jpg";
import switzerlandPreviewPhotoThumb from "../../img/photos/previews/2016_switzerland.thumb.jpg";
import niagaraPreviewPhoto from "../../img/photos/previews/2019_05_niagara.jpg";
import niagaraPreviewPhotoThumb from "../../img/photos/previews/2019_05_niagara.thumb.jpg";
import nyPreviewPhoto from "../../img/photos/previews/2019_05_NY.jpg";
import nyPreviewPhotoThumb from "../../img/photos/previews/2019_05_NY.thumb.jpg";
import roadtripPreviewPhoto from "../../img/photos/previews/2019_07_us_roadtrip.jpg";
import roadtripPreviewPhotoThumb from "../../img/photos/previews/2019_07_us_roadtrip.thumb.jpg";
import spainPreviewPhoto from "../../img/photos/previews/2019_09_spain.jpg";
import spainPreviewPhotoThumb from "../../img/photos/previews/2019_09_spain.thumb.jpg";
import barcelonaPreviewPhoto from "../../img/photos/previews/2022_09_barcelona_3.jpg";
import barcelonaPreviewPhotoThumb from "../../img/photos/previews/2022_09_barcelona_3.thumb.jpg";
import lasPalmasPreviewPhoto from "../../img/photos/previews/2023_las_palmas.jpg";
import lasPalmasPreviewPhotoThumb from "../../img/photos/previews/2023_las_palmas.thumb.jpg";
import { BorderBPaper } from "./BorderPaper";
import { ImageWithDialog } from "./ImageWithDialog";

type PhotoType = "london" | "switzerland" | "niagara" | "ny" | "roadtrip" | "spain" | "barcelona" | "lasPalmas";

const PHOTO_LIST: PhotoType[] = [
  "london",
  "switzerland",
  "niagara",
  "ny",
  "roadtrip",
  "spain",
  "barcelona",
  "lasPalmas",
];
const PREVIEW_PHOTO_MAP: { [key in PhotoType]: { url: string; thumbnail: string } } = {
  london: {
    url: londonPreviewPhoto,
    thumbnail: londonPreviewPhotoThumb,
  },
  switzerland: {
    url: switzerlandPreviewPhoto,
    thumbnail: switzerlandPreviewPhotoThumb,
  },
  niagara: {
    url: niagaraPreviewPhoto,
    thumbnail: niagaraPreviewPhotoThumb,
  },
  ny: {
    url: nyPreviewPhoto,
    thumbnail: nyPreviewPhotoThumb,
  },
  roadtrip: {
    url: roadtripPreviewPhoto,
    thumbnail: roadtripPreviewPhotoThumb,
  },
  spain: {
    url: spainPreviewPhoto,
    thumbnail: spainPreviewPhotoThumb,
  },
  barcelona: {
    url: barcelonaPreviewPhoto,
    thumbnail: barcelonaPreviewPhotoThumb,
  },
  lasPalmas: {
    url: lasPalmasPreviewPhoto,
    thumbnail: lasPalmasPreviewPhotoThumb,
  },
};
const PHOTO_MAP: { [key in PhotoType]: { url: string; thumbnail: string } } = {
  london: {
    url: londonPhoto,
    thumbnail: londonPhotoThumb,
  },
  switzerland: {
    url: switzerlandPhoto,
    thumbnail: switzerlandPhotoThumb,
  },
  niagara: {
    url: niagaraPhoto,
    thumbnail: niagaraPhotoThumb,
  },
  ny: {
    url: nyPhoto,
    thumbnail: nyPhotoThumb,
  },
  roadtrip: {
    url: roadtripPhoto,
    thumbnail: roadtripPhotoThumb,
  },
  spain: {
    url: spainPhoto,
    thumbnail: spainPhotoThumb,
  },
  barcelona: {
    url: barcelonaPhoto,
    thumbnail: barcelonaPhotoThumb,
  },
  lasPalmas: {
    url: lasPalmasPhoto,
    thumbnail: lasPalmasPhotoThumb,
  },
};

const PREFIX = "PreviewPhotos";
const classes = {
  wrapper: `${PREFIX}-wrapper`,
  image: `${PREFIX}-image`,
  box: `${PREFIX}-box`,
};

const Root = styled("div")(({ theme }) => ({
  position: "relative",
  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    width: theme.spacing(4),
    background: `linear-gradient(270deg, ${alpha(theme.palette.background.default, 0)} 0%, ${
      theme.palette.background.default
    } 100%)`,
    zIndex: 2,
    userSelect: "none",
    pointerEvents: "none",
  },
  "&:after": {
    content: "''",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: theme.spacing(4),
    background: `linear-gradient(90deg, ${alpha(theme.palette.background.default, 0)} 0%, ${
      theme.palette.background.default
    } 100%)`,
    zIndex: 2,
    userSelect: "none",
    pointerEvents: "none",
  },
  [`& .${classes.wrapper}`]: {
    display: "flex",
    gap: theme.spacing(2),
    overflowX: "auto",
    padding: theme.spacing(0, 4),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      gap: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      gap: theme.spacing(8),
      padding: theme.spacing(0, 8),
    },
  },
  [`& .${classes.image}`]: {
    borderWidth: theme.spacing(1),
    minWidth: "min(65vw, 430px)",
    maxWidth: "430px",
  },
  [`& .${classes.box}`]: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
}));

export const Photos: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <Box className={classes.wrapper}>
        {PHOTO_LIST.map((photo, index) => (
          <Box key={index}>
            <Paper variant="outlined" className={classes.image} elevation={0}>
              <ImageWithDialog
                previewSrc={PREVIEW_PHOTO_MAP[photo]}
                originalSrc={PHOTO_MAP[photo]}
                label={t(`home.sections.photos.items.${photo}`)}
              />
            </Paper>
            <BorderBPaper elevation={0}>
              <Box className={classes.box}>
                <Typography variant="h6">{t(`home.sections.photos.items.${photo}`)}</Typography>
              </Box>
            </BorderBPaper>
          </Box>
        ))}
      </Box>
    </Root>
  );
};
