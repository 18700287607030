import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ProgressiveImageLoading from "../components/common/ProgressiveImageLoading";

const PREFIX = "PhotoModal";
const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const Root = styled(DialogTitle)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

interface PhotoModalProps {
  onClose: () => void;
  title: string;
  imageSrc: {
    url: string;
    thumbnail: string;
  };
}

export const PhotoModal: React.FC<PhotoModalProps> = ({ onClose, imageSrc, title }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog fullScreen={fullScreen} open={true} onClose={onClose} scroll="body" maxWidth="md" fullWidth>
      <Root variant="h5">
        <IconButton className={classes.closeButton} aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Box sx={{ textAlign: "center" }}>{title}</Box>
      </Root>
      <DialogContent>
        <ProgressiveImageLoading src={imageSrc.url} thumb={imageSrc.thumbnail} blur forceLoad alt={title} />
      </DialogContent>
    </Dialog>
  );
};
