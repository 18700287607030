import MenuIcon from "@mui/icons-material/Menu";
import {
  alpha,
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { CONTACT_ID, COVER_ID, HOUSING_ID, INFO_ID, PHOTOS_ID, SCROLL_DURATION, STORY_ID } from "../../constants";
import { LanguageSwitcher } from "../LanguageSwitcher";

const ITEMS: { id: string; i18nKey: string }[] = [
  { id: COVER_ID, i18nKey: "home" },
  { id: INFO_ID, i18nKey: "info" },
  { id: HOUSING_ID, i18nKey: "housing" },
  { id: STORY_ID, i18nKey: "story" },
  { id: PHOTOS_ID, i18nKey: "photos" },
  { id: CONTACT_ID, i18nKey: "contact" },
];

const drawerWidth = 240;
const OFFSET = 48;

const PREFIX = "TopBar";
const classes = {
  navbarToolbar: `${PREFIX}-navbarToolbar`,
  navbarItem: `${PREFIX}-navbarItem`,
  navbarItemActive: `${PREFIX}-navbarItemActive`,
};

const Root = styled(AppBar)(({ theme }) => ({
  [`& .${classes.navbarToolbar}`]: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: alpha(theme.palette.primary.main, 0.5),
  [`&.${classes.navbarItemActive}`]: {
    color: theme.palette.primary.main,
  },
}));

export const TopBar: React.FC = () => {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {ITEMS.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <StyledLink
                to={item.id}
                spy={true}
                smooth={true}
                offset={-OFFSET}
                duration={SCROLL_DURATION}
                activeClass={classes.navbarItemActive}
              >
                <Typography variant="subtitle1">{t(`home.navbar.${item.i18nKey}`)}</Typography>
              </StyledLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Root position="sticky" color="secondary" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar variant="dense" className={classes.navbarToolbar}>
            {!desktop && <LanguageSwitcher />}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              {ITEMS.map((item) => (
                <Button key={item.id}>
                  <StyledLink
                    to={item.id}
                    spy={true}
                    smooth={true}
                    offset={-OFFSET}
                    duration={SCROLL_DURATION}
                    activeClass={classes.navbarItemActive}
                  >
                    <Typography variant="subtitle1">{t(`home.navbar.${item.i18nKey}`)}</Typography>
                  </StyledLink>
                </Button>
              ))}
            </Box>
            {desktop && (
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <LanguageSwitcher />
              </>
            )}
          </Toolbar>
        </Container>
      </Root>
      <Box component="nav">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
