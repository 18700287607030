import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";
import { useInView } from "react-intersection-observer";

const PREFIX = "ProgressiveImageLoading";
const classes = {
  original: `${PREFIX}-original`,
  image: `${PREFIX}-image`,
  thumb: `${PREFIX}-thumb`,
  blur: `${PREFIX}-blur`,
  hide: `${PREFIX}-hide`,
};

interface ProgressiveImageLoadingProps {
  src: string;
  thumb: string;
  blur?: boolean;
  forceLoad?: boolean;
  alt: string;
}

const Root = styled("div")(({ theme }) => ({
  overflow: "hidden",
  display: "flex",
  position: "relative",
  minWidth: "100%",
  [`& .${classes.image}`]: {
    minWidth: "100%",
    opacity: 1,
    transition: "opacity 2s ease-in-out",
    [`&.${classes.original}`]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "auto",
    },
    [`&.${classes.thumb}${classes.blur}`]: {
      filter: "blur(8px)",
    },
    [`&.${classes.hide}`]: {
      opacity: 0,
    },
  },
}));

const ProgressiveImageLoading: React.FC<ProgressiveImageLoadingProps> = ({ src, thumb, blur, forceLoad, alt }) => {
  const [ready, setReady] = useState(false);
  const triggeredRef = useRef(false);

  const { ref: wrapperRef, inView: intersecting } = useInView({
    threshold: 0,
    rootMargin: "100px",
  });

  useEffect(() => {
    if (!triggeredRef.current && (intersecting || forceLoad)) {
      triggeredRef.current = true;
      const buffer = new Image();
      buffer.onload = () => setReady(true);
      buffer.src = src;
    }
  }, [intersecting, src, forceLoad]);

  return (
    <Root ref={wrapperRef}>
      {ready ? <img className={cx(classes.image, classes.original)} src={src} alt={alt} /> : null}
      <img
        src={thumb}
        className={cx(classes.image, classes.thumb, { [classes.blur]: blur, [classes.hide]: ready })}
        alt="Thumbnail"
      />
    </Root>
  );
};

export default ProgressiveImageLoading;
