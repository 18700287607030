import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { ButtonBase, styled } from "@mui/material";
import React from "react";
import { useModal } from "../../context/modal-provider";
import { PhotoModal } from "../../modal/PhotoModal";
import ProgressiveImageLoading from "../common/ProgressiveImageLoading";

const PREFIX = "ImageWithDialog";
const classes = {
  backdrop: `${PREFIX}-backdrop`,
  icon: `${PREFIX}-icon`,
};

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  minWidth: "100%",
  "&:hover, &.Mui-focusVisible": {
    [`& .${classes.backdrop}:before`]: {
      opacity: 0.5,
    },
    [`& .${classes.icon}`]: {
      opacity: 1,
    },
  },
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: "''",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.main,
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
  [`& .${classes.icon}`]: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    zIndex: 1,
    opacity: 0,
    transition: theme.transitions.create("opacity"),
  },
}));

interface ImageWithDialogProps {
  previewSrc: {
    url: string;
    thumbnail: string;
  };
  originalSrc: {
    url: string;
    thumbnail: string;
  };
  label: string;
}
export const ImageWithDialog: React.FC<ImageWithDialogProps> = ({ previewSrc, label, originalSrc }) => {
  const { showModal } = useModal();

  return (
    <ImageButton focusRipple onClick={() => showModal(PhotoModal, { imageSrc: originalSrc, title: label })}>
      <ProgressiveImageLoading src={previewSrc.url} thumb={previewSrc.thumbnail} blur alt={label} />
      <ImageBackdrop className={classes.backdrop}>
        <FullscreenIcon className={classes.icon} />
      </ImageBackdrop>
    </ImageButton>
  );
};
