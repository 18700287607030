import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { Element as ScrollElement } from "react-scroll";

const PREFIX = "Section";
const classes = {
  children: `${PREFIX}-children`,
};

const Root = styled("div")(({ theme }) => ({
  padding: theme.spacing(4, 0),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [`& .${classes.children}`]: {
    maxWidth: "100%",
  },
}));

interface SectionProps {
  title: string;
  children: React.ReactNode;
  id: string;
}

export const Section: React.FC<SectionProps> = ({ title, id, children }) => {
  const first = title.substring(0, 1);
  const last = title.substring(title.length - 1, title.length);
  const middle = title.substring(1, title.length - 1);
  return (
    <ScrollElement name={id} id={id}>
      <Root>
        <Typography textAlign="center" variant="h2" color="primary" mb={8}>
          <Box component="span" sx={{ fontFeatureSettings: "'ss04' on" }}>
            {first}
          </Box>
          {middle}
          <Box component="span" sx={{ fontFeatureSettings: "'ss05' on" }}>
            {last}
          </Box>
        </Typography>
        <div className={classes.children}>{children}</div>
      </Root>
    </ScrollElement>
  );
};
