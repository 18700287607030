import { Box, Container, Divider, Paper, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Element as ScrollElement } from "react-scroll";
import { BorderTBPaper } from "../components/home/BorderPaper";
import { Photos } from "../components/home/Photos";
import { Section } from "../components/home/Section";
import { SectionContent } from "../components/home/SectionContent";
import { Story } from "../components/home/Story";
import { TopBar } from "../components/home/TopBar";
import { BRIDESMAID_ID, CONTACT_ID, COVER_ID, HOUSING_ID, INFO_ID, PHOTOS_ID, STORY_ID } from "../constants";
import coverImage from "../img/photos/cover.jpg";
import coverImageThumb from "../img/photos/cover.thumb.jpg";
import leftOrnament from "../img/left.png";
import rightOrnament from "../img/right.png";
import topOrnament from "../img/top.png";
import topLeftOrnament from "../img/top_left.png";
import topRightOrnament from "../img/top_right.png";
import ProgressiveImageLoading from "../components/common/ProgressiveImageLoading";
import cx from "classnames";

const PREFIX = "HomePage";
const classes = {
  content: `${PREFIX}-content`,
  ornaments: `${PREFIX}-ornaments`,
  ornamentsNotLoaded: `${PREFIX}-ornamentsNotLoaded`,
  cover: `${PREFIX}-cover`,
  coverContainer: `${PREFIX}-coverContainer`,
  coverContent: `${PREFIX}-coverContent`,
  contact: `${PREFIX}-contact`,
  coverImage: `${PREFIX}-coverImage`,
  box: `${PREFIX}-box`,
  footer: `${PREFIX}-footer`,
  footerDivider: `${PREFIX}-footerDivider`,
  topOrnament: `${PREFIX}-topOrnament`,
  topLeftOrnament: `${PREFIX}-topLeftOrnament`,
  topRightOrnament: `${PREFIX}-topRightOrnament`,
  leftOrnament: `${PREFIX}-leftOrnament`,
  rightOrnament: `${PREFIX}-rightOrnament`,
  infoSection: `${PREFIX}-infoSection`,
  map: `${PREFIX}-map`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.content}`]: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  [`& .${classes.ornaments}`]: {
    transition: "opacity 1s ease-in-out",
    [`&.${classes.ornamentsNotLoaded}`]: {
      opacity: 0,
    },
  },
  [`& .${classes.topOrnament}`]: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: theme.spacing(6),
    backgroundImage: `url(${topOrnament})`,
    backgroundRepeatX: "repeat",
    backgroundRepeatY: "no-repeat",
    backgroundSize: "cover",
    userSelect: "none",
    pointerEvents: "none",
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(10),
    },
    [theme.breakpoints.up("md")]: {
      height: theme.spacing(18),
    },
    [theme.breakpoints.up("xl")]: {
      height: "292px",
      backgroundSize: "auto",
    },
  },
  [`& .${classes.topLeftOrnament}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "50%",
    height: "100%",
    backgroundImage: `url(${topLeftOrnament})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    userSelect: "none",
    pointerEvents: "none",
    [theme.breakpoints.up("xl")]: {
      width: "720px",
    },
  },
  [`& .${classes.topRightOrnament}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    width: "50%",
    height: "100%",
    backgroundImage: `url(${topRightOrnament})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    userSelect: "none",
    pointerEvents: "none",
    [theme.breakpoints.up("xl")]: {
      width: "720px",
    },
  },
  [`& .${classes.leftOrnament}`]: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: theme.spacing(4),
    backgroundImage: `url(${leftOrnament})`,
    backgroundSize: "contain",
    userSelect: "none",
    pointerEvents: "none",
    zIndex: 1200,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(11),
    },
    [theme.breakpoints.up("xl")]: {
      width: "162px",
    },
  },
  [`& .${classes.rightOrnament}`]: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: theme.spacing(4),
    backgroundImage: `url(${rightOrnament})`,
    backgroundSize: "contain",
    userSelect: "none",
    pointerEvents: "none",
    zIndex: 1200,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(11),
    },
    [theme.breakpoints.up("xl")]: {
      width: "162px",
    },
  },
  [`& .${classes.cover}`]: {
    position: "relative",
    width: "100%",
  },
  [`& .${classes.footerDivider}`]: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  [`& .${classes.coverContainer}`]: {
    display: "flex",
    justifyContent: "center",
  },
  [`& .${classes.coverContent}`]: {
    position: "relative",
    minWidth: "100%",
    padding: theme.spacing(15, 0, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(22),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(27),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(33),
    },
  },
  [`& .${classes.coverImage}`]: {
    borderWidth: theme.spacing(1),
    minWidth: "100%",
  },
  [`& .${classes.contact}`]: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      gap: theme.spacing(8),
      flexDirection: "row",
    },
  },
  [`& .${classes.box}`]: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(8),
    },
  },
  [`& .${classes.footer}`]: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
  [`& .${classes.infoSection}`]: {
    display: "grid",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
  [`& .${classes.map}`]: {
    marginTop: theme.spacing(2),
    "& iframe": {
      height: "450px",
      minHeight: "450px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      marginLeft: theme.spacing(-4),
      "& iframe": {
        height: "100%",
      },
    },
  },
}));

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const [ornamentsPreloaded, setOrnamentsPreloaded] = useState(false);
  useEffect(() => {
    const images = [topOrnament, topLeftOrnament, topRightOrnament, leftOrnament, rightOrnament];
    const promises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = resolve;
        img.onerror = reject;
      });
    });
    Promise.all(promises).then(() => setOrnamentsPreloaded(true));
  }, []);
  return (
    <Root>
      <div className={classes.content}>
        <div className={cx(classes.ornaments, { [classes.ornamentsNotLoaded]: !ornamentsPreloaded })}>
          <div className={classes.topOrnament} />
          <div className={classes.topLeftOrnament} />
          <div className={classes.topRightOrnament} />
          <div className={classes.leftOrnament} />
          <div className={classes.rightOrnament} />
        </div>
        <div className={classes.cover}>
          <Container maxWidth="lg" className={classes.coverContainer}>
            <ScrollElement name={COVER_ID} id={COVER_ID} className={classes.coverContent}>
              <Typography variant="h1" color="primary" textAlign="center" mb={-3}>
                <Box component="span" sx={{ fontFeatureSettings: "'ss04' on" }}>
                  b
                </Box>
                arbor
                <Box component="span" sx={{ fontFeatureSettings: "'swsh' on" }}>
                  a
                </Box>
              </Typography>
              <Typography variant="h6" color="primary" textAlign="center" mb={1} textTransform="uppercase">
                {t("home.sections.cover.connector")}
              </Typography>
              <Typography variant="h1" color="primary" textAlign="center" mb={4}>
                vladimí
                <Box component="span" sx={{ fontFeatureSettings: "'ss01' on" }}>
                  r
                </Box>
              </Typography>
              <Paper variant="outlined" elevation={0} className={classes.coverImage}>
                <ProgressiveImageLoading src={coverImage} thumb={coverImageThumb} blur alt="cover" />
              </Paper>
            </ScrollElement>
          </Container>
        </div>
        <TopBar />
        <Container maxWidth="lg">
          <Section title={t("home.sections.info.title")} id={INFO_ID}>
            <Box className={classes.infoSection}>
              <BorderTBPaper elevation={0}>
                <Box className={classes.box}>
                  <SectionContent i18nKey="home.sections.info.content" />
                </Box>
              </BorderTBPaper>
              <div className={classes.map}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.google.com/maps/d/embed?mid=1MqJRYQDP1oFhAS3ZUiPg42LrDQyMWyOk"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </Box>
          </Section>
          <Section title={t("home.sections.housing.title")} id={HOUSING_ID}>
            <BorderTBPaper elevation={0}>
              <Box className={classes.box} textAlign="center">
                <SectionContent i18nKey="home.sections.housing.content" />
              </Box>
            </BorderTBPaper>
          </Section>
          <Section title={t("home.sections.story.title")} id={STORY_ID}>
            <Story />
          </Section>
          <Section title={t("home.sections.photos.title")} id={PHOTOS_ID}>
            <Photos />
          </Section>
          <div className={classes.contact}>
            <Section title={t("home.sections.contact.title")} id={CONTACT_ID}>
              <BorderTBPaper elevation={0}>
                <Box className={classes.box} textAlign="center">
                  <SectionContent i18nKey="home.sections.contact.content" />
                </Box>
              </BorderTBPaper>
            </Section>
            <Section title={t("home.sections.bridesmaid.title")} id={BRIDESMAID_ID}>
              <BorderTBPaper elevation={0}>
                <Box className={classes.box} textAlign="center">
                  <SectionContent i18nKey="home.sections.bridesmaid.content" />
                </Box>
              </BorderTBPaper>
            </Section>
          </div>
        </Container>
        <Divider className={classes.footerDivider} />
        <Container maxWidth="lg">
          <Box className={classes.footer}>
            <Typography color="primary" textAlign="center">
              <Trans i18nKey={"footer"} components={[<Box component="span" sx={{ fontWeight: "medium" }} />]} />
            </Typography>
            <Typography color="primary" textAlign="center">
              vladko.baska@gmail.com
            </Typography>
          </Box>
        </Container>
      </div>
    </Root>
  );
};
