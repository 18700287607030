import {alpha, Paper, styled} from "@mui/material";

export const BorderTBPaper = styled(Paper)(({theme}) => ({
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: alpha(theme.palette.primary.main, 0.5),
  borderLeft: "none",
  borderRight: "none",
}));

export const BorderBPaper = styled(Paper)(({theme}) => ({
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: alpha(theme.palette.primary.main, 0.5),
  borderLeft: "none",
  borderRight: "none",
  borderTop: "none",
}));
