import { Box, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

interface SectionContentProps {
  i18nKey: string;
}

export const SectionContent: React.FC<SectionContentProps> = ({ i18nKey }) => {
  const { t } = useTranslation();
  const items = t<string, string[]>(i18nKey, { returnObjects: true });
  return (
    <>
      {items.map((item, index) => (
        <Typography key={index} gutterBottom={index < items.length - 1}>
          <Trans i18nKey={item} components={[<Box component="span" sx={{ fontWeight: "medium" }} />]} />
        </Typography>
      ))}
    </>
  );
};
