import "@fontsource/eb-garamond/400-italic.css";
import "@fontsource/eb-garamond/400.css";
import "@fontsource/eb-garamond/500-italic.css";
import "@fontsource/eb-garamond/500.css";
import "@fontsource/herr-von-muellerhoff";
import "@fontsource/josefin-sans/300.css";
import "@fontsource/josefin-sans/400.css";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import WebFont from "webfontloader";
import { AppWrapper } from "./AppWrapper";
import { ModalProvider } from "./context/modal-provider";
import ShamelessOtf from "./font/ShamelessDeluxe.otf";
import i18n from "./i18n";
import { nullFunction } from "./utils";

i18n.on("languageChanged", nullFunction);

const EntryPoint = (): JSX.Element => {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Josefin Sans", "EB Garamond", "Herr Von Muellerhoff"],
      },
    });
  }, []);
  return (
    <ModalProvider>
      <Router>
        <Helmet>
          <link rel="preload" href={ShamelessOtf} as="font" crossOrigin="anonymous" />
        </Helmet>
        <AppWrapper />
      </Router>
    </ModalProvider>
  );
};

export default EntryPoint;
